.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0f0c29;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin: 20px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content.show {
  display: block;
}


.dropdown-content p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content p:hover {
  background-color: #ddd;
}

.dropdown-toggle {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.horizontal-lines {
  width: 20px;
  height: 2px;
  background-color: white;
  margin: 2px 0;
}

.App-elveron {
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.social-container {
  background: #0f0c29;
  padding: 25px 50px;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: rgb(207, 110, 18);
}

a.twitch {
  color: rgb(170, 93, 177);
}

a.kick {
  color: rgb(18, 241, 29);
}

a.discord {
  color: rgb(213, 216, 224);
}